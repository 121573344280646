<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<div class="mian" v-loading="loading">
			<div class="titles">套餐资费与购买</div>
			<div class="form">
				<div class="title fc">
					<div class="title_item fcc" style="width: 233px;">描述</div>
					<div class="title_item fcc" style="width: 331px;">价格（元）</div>
					<div class="title_item fcc" style="width: 407px;">价格（元）</div>
					<div class="title_item fcc" style="width: 229px;">购买</div>
				</div>
				<div v-for="(item,index) in list" :key="index">
					<div class="list fc" v-for="(item2,index2) in item.pricedata" :key="index2">
						<div class="list_item fcc" style="width: 233px;">{{item2.title}}</div>
						<div class="list_item fcc" style="width: 331px;">{{item2.price}}</div>
						<div class="list_item fcc" style="width: 407px;">{{item2.subtext}}</div>
						<div class="list_item fcc" style="width: 229px;color: #006DFF;cursor: pointer;"
							@click="changeouterVisible(item.level,item2.unit)">点击购买</div>
					</div>
				</div>
			</div>
		</div>
		<div ref="alipayWap" v-html="alipay" />
		<div style="height: 60px;"></div>
		<el-dialog title="支付选择" :visible.sync="outerVisible" width="600px">
			<div class="tabbox fcs">
				<div :class="form.paytype == 'wechat'?'tab-a fcc':'tab fcc'" @click="form.paytype = 'wechat'">
					<img :src="payinfo[0].image" alt="">
				</div>
				<div :class="form.paytype == 'alipay'?'tab-a fcc':'tab fcc'" @click="form.paytype = 'alipay'">
					<img :src="payinfo[1].image" alt="">
				</div>
			</div>
			<div class="fcc" style="margin:50px 0 30px 0;">
				<el-button type="primary" style="width: 150px;" @click="pay">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				loading: false,
				outerVisible: false,
				payinfo: [],
				list: [],
				form: {
					level: '',
					unit: '',
					paytype: 'wechat'
				},
				alipay: ''
			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.getlist();
		},
		methods: {
			//获取轮播图
			getbanner() {
				let data = {
					mark: 'price01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 360 / 1920
						});
					}
				})
			},
			changeouterVisible(level, unit) {
	;
						

						this.token= `https://zxadmin.zx3p.com/?Watercup=${this.$Base64.encode(sessionStorage.getItem('token'))}&cid=${this.$Base64.encode('1323215444')}&pid=${this.$Base64.encode('66699888452')}`
						 window.location.href = this.token
				// this.form.level = level;
				// this.form.unit = unit;
				// this.outerVisible = true;
			},
			getlist() {
				this.loading = true;
				this.$api.home.smslist().then(res => {
					if (res.code == 1) {
						this.payinfo = res.data.paytypeList;
						this.list = res.data.smsList;
					}
					this.loading = false;
				})
			},
			pay() {
				this.$api.home.submit(this.form).then(res => {
					if (res.code == 1) {
						// 渲染支付页面
						this.alipay = res.data;
						// 防抖避免重复支付
						this.$nextTick(() => {
							// 提交支付表单
							this.$refs.alipayWap.children[0].submit();
						});
					}
				})
			}
		}
	}
</script>

<style scoped>
	.mian {
		width: 1200px;
		margin: 10px auto;
	}

	.titles {
		font-size: 24px;
		color: #24272C;
		margin: 30px 0;
	}

	.form {
		width: 1200px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		box-sizing: border-box;
	}

	.form .title {
		box-sizing: border-box;
		width: 1200px;
		height: 55px;
		font-size: 16px;
	}

	.form .title .title_item {
		height: 55px;
		box-sizing: border-box;
		border: 1px solid #CCCCCC;
	}

	.form .list {
		box-sizing: border-box;
		width: 1200px;
		height: 55px;
		font-size: 13px;
	}

	.form .list .list_item {
		height: 55px;
		box-sizing: border-box;
		border: 1px solid #CCCCCC;
	}

	.tabbox {
		width: 400px;
		margin: 40px auto;
	}

	.tabbox .tab {
		width: 180px;
		height: 60px;
		border: #CCCCCC solid 1px;
		cursor: pointer;
	}

	.tabbox .tab-a {
		width: 180px;
		height: 60px;
		border: #CCCCCC solid 1px;
		cursor: pointer;
		background: #DBE1FF;
	}

	.tabbox .tab img {
		width: 120px;
	}

	.tabbox .tab-a img {
		width: 120px;
	}
</style>